/* .side_bar_section > .btn img {
    filter: invert(1) !important;
}

.search_inner.open {
    height: 140px !important;
}

.btn.bonus {
    background-color: rgb(104 162 0) !important;
}

.footer-wrapper {
    background-color: rgb(104 162 0/40%) !important;
}

.footer-imgs img {
    filter: grayscale(1) drop-shadow(2px 4px 6px black) !important;
}

.jackpot_wrapper {
    background: rgb(40 61 2) !important;
    border-image: linear-gradient(0.25turn, #161816, #adb4b3, #161816) 2 0 2 0 !important;
} */
.header-component{
  background-color: unset;
}
.footer-section.images{
  border-image: linear-gradient(0.25turn, #0d2523, rgba(231, 185, 91, 0.35), #0d2523) 1 0 1
}
.search_icon{
  img{
    @include recolor(#e7b95b)}
}
.btn.bonus {

}

.header-logo {
  width: 20vw;
  position: relative;
  left: auto;
  height: 90%;
}

.bonus_slider {
  height: 80vh;
}
.jackpot_wrapper{
  background: #011a1c;
}

.bonus_wrapper{
  width: 100vw;
  height: 80vh;
  min-height: 250px;
  max-height: initial;
  justify-content: end;
  .bonus_container{
    margin-right: 70px;
    img{
      height: 100% !important;
    }
  }
}
.carousel-initialized{
  padding: 0 !important;
}
@media screen and (max-width: 600px) {
  .bonus_wrapper{
    justify-content: center;
    .bonus_container{
      margin-right: 0px;
      img{
        height: 80% !important;
      }
    }
  }
}
